import React from "react"
//import {Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"



const GardenPage = () => (
    <Layout>
        <SEO title="My Garden"/>
        <h1>My Garden</h1>
        <div style={{maxWidth: `900px`, overflow: "auto"}}>
            <div style={{display: "inline-block", maxWidth: `620px`}}>
                <p>Welcome to my garden page!</p>
                <p> Pictures coming soon. </p>

            </div>

        </div>
    </Layout>
)

export default GardenPage
